<template>
    <v-dialog
        :key="pageKey"
        persistent
        v-model="editDialog"
        transition="dialog-bottom-transition"
        overlay=false
        scrollable
        width="500px"
        min-width="500px"
        max-width="600px"
    >
        <v-card>
            <v-card-title class="success white--text justify-space-between">
                <span>{{ updateMode == false ? $t('message.addGrade') : $t('message.updateGrade') + ": " + gradeName }}</span>
                <HelpPopUpV2 help-page-component="AddGrade" :light="true" :no-padding="true"></HelpPopUpV2>
            </v-card-title>
            <v-card-text>
                <v-overlay
                    :value="loading.get"
                    absolute
                    opacity="0.15"
                >
                    <v-row>
                        <v-col class="text-center">
                            <v-progress-circular
                                color="primary"
                                indeterminate
                                size="40"
                                width="6"
                            ></v-progress-circular>
                        </v-col>
                    </v-row>
                </v-overlay>
                <v-form
                    lazy-validation
                    ref="gradesForm"
                    v-model="validForm"
                >
                    <v-layout row pt-3>
                        <v-flex xs4 lg4 md6 pl-5 py-1 class="align-center">{{ $t('message.grade') }}</v-flex>
                        <v-flex xs8 lg8 md6 py-1>
                            <v-text-field
                                :value="Grade__title"
                                :rules="[rules.title]"
                                autocomplete="off"
                                class="force-text-left"
                                clearable
                                dense
                                hide-details="auto"
                                solo
                                @change="Grade__title = $event"
                            ></v-text-field>
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs4 lg4 md6 pl-5 py-1 class="align-center">{{ $t('message.gradeGroup') }}</v-flex>
                        <v-flex xs8 lg8 md6 py-1>
                            <v-combobox
                                :items="allParentGrades"
                                :rules="[rules.parent]"
                                :value="Parent__title"
                                autocomplete="off"
                                class="force-text-left"
                                clearable
                                dense
                                hide-details="auto"
                                item-text="Grade.title"
                                item-value="Grade.id"
                                return-object
                                solo
                                @change="updateParentGroup('Parent__title',$event)"
                            ></v-combobox>
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs4 lg4 md6 pl-5 py-1 class="align-center">{{ $t('message.productGroups') }}</v-flex>
                        <v-flex xs8 lg8 md6 py-1>
                            <v-autocomplete
                                :items=sortedAllProductGroups
                                :value="Grade__productgroups"
                                clearable
                                dense
                                hide-details="auto"
                                item-text="ProductGroup.title"
                                item-value="ProductGroup.id"
                                multiple
                                solo
                                @click:clear="clearOptions"
                            >
                                <template v-slot:item="{item}">
                                    <v-list-item
                                        class="pl-0 ml-0"
                                        dense
                                        ripple
                                        @click="toggleAll(item.ProductGroup.id)"
                                        v-if="'type' in item.ProductGroup && allProductGroups.filter(opt => opt.Parent.title == item.ProductGroup.title).length > 1"
                                    >
                                        <v-list-item-content>
                                            <v-list-item-title class="font-md font-weight-bold" v-if="item.ProductGroup.title != null">{{ item.ProductGroup.title }}</v-list-item-title>
                                        </v-list-item-content>
                                        <v-list-item-action class="d-flex flex-row">
                                            <span class="font-sm mr-2">{{ $t('message.selectAll') }}</span>
                                            <v-icon :color="selectedAllOptions[item.ProductGroup.title] ? 'success darken-4' : ''" v-if="selectedAllOptions[item.ProductGroup.title] && selectedSomeOptions[item.ProductGroup.title] == false">mdi-close-box</v-icon>
                                            <v-icon :color="selectedAllOptions[item.ProductGroup.title] ? 'success darken-4' : ''" v-if="selectedSomeOptions[item.ProductGroup.title] && selectedAllOptions[item.ProductGroup.title] == false">mdi-minus-box</v-icon>
                                            <v-icon :color="selectedAllOptions[item.ProductGroup.title] ? 'success darken-4' : ''" v-if="selectedAllOptions[item.ProductGroup.title] == false && selectedSomeOptions[item.ProductGroup.title] == false">mdi-checkbox-blank-outline</v-icon>
                                        </v-list-item-action>
                                    </v-list-item>
                                    <v-list-item
                                        class="pl-0 ml-0"
                                        dense
                                        ripple
                                        v-else-if="'type' in item.ProductGroup && allProductGroups.filter(opt => opt.Parent.title == item.ProductGroup.title).length == 1"
                                    >
                                        <v-list-item-content>
                                            <v-list-item-title class="font-md font-weight-bold" v-if="item.ProductGroup.title != null">{{ item.ProductGroup.title }}</v-list-item-title>
                                            <v-list-item-title class="font-md font-weight-bold" v-else>{{ $t('message.others') }}</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item
                                        dense
                                        ripple
                                        v-else
                                        @click="addRemoveFromSelectedOptions(item)"
                                    >
                                        <v-list-item-action>
                                            <v-icon color="success darken-2" v-if="selectedAllOptions[item.Parent.title]">mdi-checkbox-marked</v-icon>
                                            <v-icon color="success darken-2" v-if="selectedAllOptions[item.Parent.title] == false && Grade__productgroups.find(opt => opt == item.ProductGroup.id)">mdi-checkbox-marked</v-icon>
                                            <v-icon v-if="selectedAllOptions[item.Parent.title] == false && sortedAllProductGroups.length == 0">mdi-checkbox-blank-outline</v-icon>
                                            <v-icon v-if="selectedAllOptions[item.Parent.title] == false && sortedAllProductGroups.length > 0 && !Grade__productgroups.find(opt => opt == item.ProductGroup.id)">mdi-checkbox-blank-outline</v-icon>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ item.ProductGroup.title }}</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-divider v-if="'type' in item.ProductGroup && allProductGroups.filter(opt => opt.Parent.title == item.ProductGroup.title).length > 1"></v-divider>
                                </template>
                                <template v-slot:selection="{ item }">
                                    <v-chip
                                        class="my-1"
                                        close
                                        @click:close="removeOption(item)"
                                        v-if="'type' in item.ProductGroup == false"
                                    >
                                        {{ item.ProductGroup.title }}
                                    </v-chip>
                                </template>
                            </v-autocomplete>
                        </v-flex>
                    </v-layout>
                </v-form>
            </v-card-text>
            <v-card-actions class="pl-7">
                <v-btn color="default" small @click="editDialog = false" >{{$t('message.dismiss')}}</v-btn>
                <v-btn
                    :loading="loading.add"
                    color="info"
                    small
                    @click="addGrade()"
                    v-if="updateMode == false"
                >{{ $t('message.add') }}</v-btn>
                <v-btn
                    :loading="loading.save"
                    color="info"
                    small
                    @click="saveGrade()"
                    v-if="updateMode == true"
                >{{ $t('message.save') }}</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {mapFieldsPrefixed} from "Helpers/vuex-map-fields-prefixed";
const HelpPopUpV2 = () => import("Components/Appic/Help/HelpPopUpV2");

export default {
    name: "AddGrade",
    components: { HelpPopUpV2 },
    props: ['gradeId','gradeName','dialog','updateMode','dialog-closed','update-done','create-done','pageKey'],
    data() {
        return {
            dialogs: {
                error: false,
                error_message: ""
            },
            edit_dialog: false,
            loading: {
                add: false,
                get: false,
                save: false
            },
            rules: {
                title: v => !!v || this.$t('message.required'),
                parent: v => !!v || this.$t('message.required'),
                product: v => !!v || this.$t('message.required')
            },
            selectedAllOptions: {},
            selectedOptions: {},
            selectedSomeOptions: {},
            validForm: true
        }
    },
    computed: {
        statePrefix() {
            return this.updateMode ? 'update' : 'current'
        },
        ...mapFieldsPrefixed('grade', {
            Grade__id: 'Grade.id',
            Grade__parent_id: 'Grade.parent_id',
            Grade__productgroups: 'Grade.productgroups',
            Grade__title: 'Grade.title',
            Parent__id: 'Parent.id',
            Parent__title: 'Parent.title',
        }, 'statePrefix'),
        ...mapGetters('grade',{
            allParentGrades: 'allParentGrades'
        }),
        ...mapGetters('productgroup',{
            allProductGroups: 'allProductGroups'
        }),
        editDialog: {
            get() {
                return this.edit_dialog;
            },
            set(value){
                this.edit_dialog = value
                if(value == false) {
                    this.$emit('dialog-closed')
                }
            }
        },
        sortedAllProductGroups() {
            let optionGroups = [];
            let options = [];
            this.allProductGroups.forEach(p => {
                if(p.Parent.title != null && optionGroups.indexOf(p.Parent.title) === -1){
                    optionGroups.push(p.Parent.title)
                }
            })
            optionGroups.sort()
            optionGroups.forEach(o => {
                this.selectedAllOptions[o] = false
                this.selectedSomeOptions[o] = false
                this.selectedOptions[o] = 0
                options.push({ProductGroup: {id: o, title: o, type: 'selectAll'}})
                let optionGroupOptions = []
                this.allProductGroups.forEach(p => {
                    if (p.Parent.title == o) {
                        optionGroupOptions.push(p)
                        this.selectedOptions[o]++
                    }
                })
                optionGroupOptions.sort((a, b) => {
                    let productA = a.ProductGroup.title
                    let productB = b.ProductGroup.title
                    productA = productA.toUpperCase()
                    productB = productB.toUpperCase()
                    if(productA < productB) return -1
                    if(productA > productB) return 1
                    return 0
                })
                optionGroupOptions.forEach((opt) => {
                    options.push(opt)
                })
            })
            return options
        }
    },
    methods: {
        ...mapActions('grade', {
            createGrade: 'createGrade',
            getAllParentGrades: 'getAllParentGrades',
            getGradeToUpdateById: 'getGradeToUpdateById',
            resetAllParentGrades: 'resetAllParentGrades',
            resetCurrentState: 'resetCurrentState',
            resetGradeToUpdate: 'resetGradeToUpdate',
            updateGrade: 'updateGrade'
        }),
        ...mapActions('productgroup',{
            getAllProductGroups: 'getAllProductGroups'
        }),
        addGrade() {
            let valid = this.$refs.gradesForm.validate()
            if (valid) {
                this.loading.add = true
                this.createGrade()
                    .then((status) => {
                        if(status == 'done'){
                            this.$toast.success(this.$t('message.successes.gradeAdded'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.loading.add = false
                            this.$emit('create-done')
                            this.editDialog = false
                        } else {
                            this.$toast.error(this.$t('message.errors.gradeNotAdded'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading.add = false
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.gradeNotAdded'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.add = false
                    })
            }
        },
        addRemoveFromSelectedOptions (item) {
            const index = this.Grade__productgroups.indexOf(item.ProductGroup.id)
            if (index >= 0) {
                this.selectedAllOptions[item.Parent.title] = false
                this.Grade__productgroups.splice(index, 1)
                this.selectedSomeOptions[item.Parent.title] = this.checkSelectedSomeOptions(item.Parent.title)
            } else {
                this.Grade__productgroups.push(item.ProductGroup.id)
                this.selectedSomeOptions[item.Parent.title] = this.checkSelectedSomeOptions(item.Parent.title)
            }
        },
        checkSelectedSomeOptions(parent) {
            let count = 0
            this.Grade__productgroups.forEach(id => {
                let productGroup = this.allProductGroups.find(p => p.ProductGroup.id == id)
                if(productGroup != null && productGroup.Parent.title == parent) count++
            })
            if(count == this.selectedOptions[parent]){
                this.selectedAllOptions[parent] = true
                return false
            } else if(count == 0){
                return false
            }
            return true
        },
        checkSelectedSomeOrAllOptions() {
            for(const parent in this.selectedSomeOptions) {
                this.selectedSomeOptions[parent] = this.checkSelectedSomeOptions(parent)
            }
        },
        clearOptions() {
            this.Grade__productgroups = []
        },
        removeOption (item) {
            const index = this.Grade__productgroups.indexOf(item.ProductGroup.id)
            if (index >= 0) this.Grade__productgroups.splice(index, 1)
        },
        saveGrade() {
            let valid = this.$refs.gradesForm.validate()
            if (valid) {
                this.loading.save = true
                this.updateGrade()
                    .then((status) => {
                        if(status == 'done'){
                            this.$toast.success(this.$t('message.successes.gradeUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.loading.save = false
                            this.$emit('update-done')
                        } else {
                            this.$toast.error(this.$t('message.errors.gradeNotUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading.save = false
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.gradeNotUpdated'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.save = false
                    })
            }
        },
        updateParentGroup(field, value) {
            if(value) {
                if(value?.Grade?.id) { //value is an object
                    this.Grade__parent_id = value.Grade.id
                    this.Parent__id = value.Grade.id
                    this.Parent__title = value.Grade.title
                } else { //value is text - new item
                    this.Grade__parent_id = 'NEW'
                    this.Parent__id = 'NEW'
                    this.Parent__title = value
                }
            } else {
                //empty value
                this.Grade__parent_id = null
                this.Parent__id = null
                this.Parent__title = null
            }
        },
        toggleAll(parent) {
            if(this.selectedAllOptions[parent]){
                this.allProductGroups.forEach(p => {
                    if(p.Parent.title == parent){
                        const index = this.Grade__productgroups.indexOf(p.ProductGroup.id)
                        if (index >= 0) this.Grade__productgroups.splice(index, 1)
                    }
                })
                this.selectedAllOptions[parent] = false
                this.selectedSomeOptions[parent] = false
            } else {
                this.allProductGroups.forEach(p => {
                    if (p.Parent.title == parent) {
                        const index = this.Grade__productgroups.indexOf(p.ProductGroup.id)
                        if (index < 0) this.Grade__productgroups.push(p.ProductGroup.id)
                    }
                })
                this.selectedAllOptions[parent] = true
                this.selectedSomeOptions[parent] = false
            }
        }
    },
    watch: {
        dialog(value) {
            if(value == true){
                if(this.updateMode == true) {
                    this.loading.get = true
                    this.getGradeToUpdateById(this.gradeId)
                        .then( () => {
                            this.loading.get = false
                            this.checkSelectedSomeOrAllOptions()
                        })
                } else {
                    this.resetCurrentState()
                }
            } else {
                if(this.updateMode == true) {
                    this.resetGradeToUpdate()
                } else {
                    this.resetCurrentState()
                }
            }
            this.edit_dialog = value
        }
    },
    mounted() {
        this.resetAllParentGrades()
        if(this.allParentGrades.length == 0) this.getAllParentGrades()
        if(this.allProductGroups.length == 0) this.getAllProductGroups()
    }
}
</script>

<style>

</style>